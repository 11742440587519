import { RGBColor } from 'react-color';
import { ColorMode, Theme } from '@themed';

export type EmbeddedUITheme = Theme & {
  colors: ColorMode & {
    modes?: {
      [k: string]: ColorMode;
    };
    primaryRgbaObject?: RGBColor[];
  };
};

const rgba = {
  aoe: {
    object: {
      r: 0,
      g: 43,
      b: 100,
      a: 1,
    },
  },
};

const generateRgba = (key: keyof typeof rgba, opacity = 1) => {
  const { r, g, b } = rgba[key].object;
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const aoe = {
  colors: {
    primary: '#002b64',
    textInvert: 'white',

    primaryLighter: {
      0: generateRgba('aoe', 1),
      1: generateRgba('aoe', 0.9),
      2: generateRgba('aoe', 0.7),
      3: generateRgba('aoe', 0.5),
      4: generateRgba('aoe', 0.3),
      5: generateRgba('aoe', 0.1),
      6: generateRgba('aoe', 0),
    },
    primaryRgba: generateRgba('aoe', 1),
    primaryRgbaObject: rgba.aoe.object,
  },
};

export default {
  aoe,
};
