/** @jsx jsx */
import { SelectedCountryContextProvider, SelectedFsTypeContextProvider, useDefaultCountry, useEmbeddedComparisonLink, useIsMounted } from '@modules';
import { useLocation } from '@reach/router';
import { Flex, jsx, ThemeProvider } from '@themed';
import React from 'react';
import { GlobalStyles } from 'src/components/core/layout/GlobalStyles';
import { FinnoscoreListProps } from '.';
import themes from '../../../gatsby-plugin-theme-ui/embeddedThemes';
import defaultTheme from '../../../gatsby-plugin-theme-ui/index';
import Profile from '../finnoscore-data/Profile';
import ReportAdvertLink from '../finnoscore-data/ReportAdvertLink';
import { finnoscoreDataStyles as styles } from '../finnoscore-data/index.styles';
import { CountrySelector } from './CountrySelector';
import { FsTabSelector, useFsSelectorOptions } from './FSTabSelector';
import InstitutionList from './FinnoscoreList';
import { FinnoscoreListSection } from './FinnoscoreListSection';
import { ComparisonLinkProvider } from './context/ComparisonContext';

export function useTheme() {
  const { search } = useLocation();
  const themeParam = new URLSearchParams(search).get('theme');

  return themeParam && themeParam in themes ? themes[themeParam as keyof typeof themes] : defaultTheme;
}

export function useVisibleSections() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  return {
    report: params.get('report') !== 'false',
    contact: params.get('contact') !== 'false',
  };
}

const ListPage = ({ isLoading, error, pageContext }: FinnoscoreListProps) => {
  const { countries, institutions } = pageContext;
  const visibleSections = useVisibleSections();
  const tabOptions = useFsSelectorOptions(institutions);

  const shouldRenderContent = countries && institutions && !error && !isLoading;

  return (
    <FinnoscoreListSection isLoading={isLoading} error={error} withSection={false}>
      {shouldRenderContent && (
        <React.Fragment>
          {visibleSections.report && <ReportAdvertLink id='report' />}
          {visibleSections.contact && <Profile id='contact' />}

          {institutions && <FsTabSelector id='chartType' sx={styles.tabSelector} options={tabOptions} />}

          <Flex id='control' sx={styles.control}>
            {countries && <CountrySelector countries={countries} />}
          </Flex>

          <Flex id='content' variant='flex.colCenter'>
            {institutions && <InstitutionList institutions={institutions} />}
          </Flex>
        </React.Fragment>
      )}
    </FinnoscoreListSection>
  );
};

const EmbeddedListPage = ({ pageContext, isLoading, error }: FinnoscoreListProps) => {
  const { countries } = pageContext;
  const { country: selectedCountry } = useDefaultCountry(countries);
  const theme = useTheme();
  const isMounted = useIsMounted();

  /**
   * We use isMounted to prevent rendering until we're on the client side.
   * This ensures consistent rendering between server and client, preventing hydration errors.
   */
  if (!isMounted) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <SelectedFsTypeContextProvider>
        {selectedCountry ? (
          <SelectedCountryContextProvider defaultValue={selectedCountry}>
            <ComparisonLinkProvider useComparisonHook={useEmbeddedComparisonLink}>
              <ListPage pageContext={pageContext} isLoading={isLoading} error={error} />
            </ComparisonLinkProvider>
          </SelectedCountryContextProvider>
        ) : (
          <FinnoscoreListSection isLoading={isLoading} error={error} />
        )}
      </SelectedFsTypeContextProvider>
    </ThemeProvider>
  );
};

export default EmbeddedListPage;
